import React, {useContext, useEffect} from 'react';
import UserContext from '@/components/UserContext';
import HomePage from './home/home';
import useSWR from "swr";
import Cookies from "js-cookie";
import {useDispatch} from 'react-redux';
import {login} from "../redux/slices/userSlice";

export default function Home() {
	const {scrollRef} = useContext(UserContext);
	const dispatch = useDispatch();

	const fetcher = (url) => {

		const localUser = localStorage.getItem('user');
		let localUserToken = localStorage.getItem('userToken');

		if (localUser === null || localUserToken === null) {
			return;
		}

		localUserToken = localUserToken.replace(["%22",'"'], '');

		fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + localUserToken || '',
			},
		}).then(async (res) => {
			const data = await res.json();
			if (data.success) {

				let userToken = localStorage.getItem('userToken');

				// userToken " to x
				userToken = userToken.replace("%22", '');
				userToken = userToken.replace('"', '');

				let forceRefresh = false;

				if(typeof Cookies.get("user") === "undefined")
					forceRefresh = true;

				localStorage.setItem('user', JSON.stringify(data.data.user));
				Cookies.set('user', JSON.stringify(data.data.user));
				Cookies.set('userToken', userToken);

				dispatch(login({
					"user" : data.data.user,
					"token" : userToken,
				}));

				if(forceRefresh)
				{
					location.reload(true);
				}
			}
		});
	};

	useEffect(() => {
		fetcher('https://api.tevkilapp.com/api/user/me');

		window.scrollTo(0, scrollRef.current.scrollPos);
		const handleScrollPos = () => {
			scrollRef.current.scrollPos = window.scrollY;
		};
		window.addEventListener('scroll', handleScrollPos);
		return () => {
			window.removeEventListener('scroll', handleScrollPos);
		};



	}, []);



	return (
		<>
			<HomePage/>
		</>
	);
}
